import React, { useEffect, useState } from "react";

import UserCard from "../../components/userCard/UserCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchTeammates,
  fetchAllExistingUsers,
  selectRoles,
  selectTeammates,
  selectUserPositions,
} from "../../redux/slice/UserSlice";
import { fetchtypesOfLeave } from "../../redux/slice/TypeOfLeaveSlice";

import "./myTeamPage.sass";

const MyTeamPage = () => {
  const dispatch = useDispatch();
  const colleagues = useSelector(selectTeammates);
  const positions = useSelector(selectUserPositions);
  const roles = useSelector(selectRoles);
  const [checked, setChecked] = useState(true);
  const [isActiveUsers, setIsActiveUsers] = useState(true);

  useEffect(() => {
    if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
      dispatch(fetchAllExistingUsers(true));
    } else {
      const userTeamIds = positions.map((positionItem) => positionItem.team.id);
      dispatch(fetchTeammates(userTeamIds));
    }
    dispatch(fetchtypesOfLeave(true));
  }, [dispatch, roles, positions, isActiveUsers]);

  const toggleTeamList = () => {
    setIsActiveUsers(!isActiveUsers);
    dispatch(fetchAllExistingUsers(!isActiveUsers));
  };

  const refreshUsers = () => {
    dispatch(fetchAllExistingUsers(isActiveUsers));
  };

  return (
    <div className="myTeamsPage">
      <div className="pageTitle h1-Montserrat">
        Mon équipe
        {(roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) && (
          <div
            className="btn-group rounded-pill switch"
            role="group"
            aria-label="Basic radio toggle button group"
            onClick={() => {
              setChecked(!checked);
              toggleTeamList();
            }}
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autoComplete="off"
              readOnly
              checked={checked}
            />
            <div className="btn btn-outline-dark rounded-pill border border-0">
              Actifs
            </div>
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autoComplete="off"
              readOnly
              checked={!checked}
            />
            <div className="btn btn-outline-dark rounded-pill border border-0">
              Inactifs
            </div>
          </div>
        )}
      </div>
      <div className="cardsContainer">
        {colleagues.map((user, index) => (
          <UserCard key={index} user={user} roles={roles} onUserUpdate={refreshUsers} />
        ))}
      </div>
    </div>
  );
};

export default MyTeamPage;
