import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUserId } from "../../redux/slice/UserSlice";
import {
  HYDRA_MEMBER,
  HYDRA_TOTALITEMS,
} from "../../services/utils/apiRessources";
import { dateHelpers } from "../../services/utils/dateHelpers";
import moment from "moment";
import {
  fetchAllTreatedLeaveRequests,
  fetchTreatedReceivedLeaveRequests,
  cancelAcceptedLeaveRequest,
} from "../../redux/slice/LeaveSlice";
import Paginator from "../paginator/Paginator";

const TreatedReceivedLeaveRequestsTable = ({
  treatedReceivedLeaveRequests,
  roles,
}) => {
  const dispatch = useDispatch();
  const userId = useSelector(selectUserId);

  const clickPagination = (page) => {
    if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_RH")) {
      dispatch(fetchAllTreatedLeaveRequests(page));
    } else if (userId) {
      dispatch(fetchTreatedReceivedLeaveRequests(userId, page));
    }
  };

  const cancelMyLeaveRequest = (id) => {
    dispatch(cancelAcceptedLeaveRequest(id));
  };

  return (
    <>
      {treatedReceivedLeaveRequests[HYDRA_MEMBER]?.length > 0 ? (
        <div className="table-wrapper">
          <table className="table  table-borderless">
            <thead>
              <tr>
                <th scope="col" className="th-treated">De</th>
                <th scope="col">Type</th>
                <th scope="col">Etat</th>
              </tr>
            </thead>
            <tbody>
              {treatedReceivedLeaveRequests[HYDRA_MEMBER]?.map((obj, key) => (
                <tr
                  key={key}
                  data-bs-toggle="modal"
                  data-bs-target={`#staticBackdrop${key}1`}
                  className="tratedHover"
                >
                  <td className="name_column">
                    <div className="cell_name">{`${obj.user.firstName} ${obj.user.lastName}`}</div>
                  </td>
                  <td>{obj.typeOfLeave.type}</td>
                  {obj.finalStatus === "ACCEPTED" && (moment(obj.startDate.split("T")[0]).isAfter() && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MANAGER")) ? (
                      <td className="response_wrapper">
                        <div
                          className="accepted_cancel"
                        >
                          Acceptée
                        </div>
                      </td>
                    ) : (
                      <td className="response_wrapper">
                        <div className="accepted">Acceptée</div>
                      </td>
                    ))}
                  {obj.finalStatus === "REJECTED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Refusé</div>
                    </td>
                  )}
                  {obj.finalStatus === "CANCELED" && (
                    <td className="response_wrapper">
                      <div className="rejected">Annulé</div>
                    </td>
                  )}
                  <td
                    className="modal fade"
                    id={`staticBackdrop${key}1`}
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex="-1"
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Infos de la demande
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          <div className="modalContent">
                            <div>
                              De&nbsp;
                              <span>{`${obj.user.firstName} ${obj.user.lastName}`}</span>
                            </div>
                            <div>
                              Demande de&nbsp;
                              <span>{obj.typeOfLeave.type}</span> de&nbsp;
                              <span>{obj.notPaidDuration}</span> jours
                            </div>
                            <div>
                              Du&nbsp;
                              <span>
                                {dateHelpers.formatDateToFrenchFormat(
                                  obj.startDate.split("T")[0]
                                )}
                              </span>
                              &nbsp;
                              <span>
                                {dateHelpers.isBeforeMidDay(
                                  obj.startDate.split("T")[1].split(":")[0]
                                )
                                  ? "Matin"
                                  : "Aprés midi"}
                              </span>
                              &nbsp; au&nbsp;
                              <span>
                                {dateHelpers.formatDateToFrenchFormat(
                                  obj.endDate.split("T")[0]
                                )}
                              </span>
                              &nbsp;
                              <span>
                                {dateHelpers.isBeforeMidDay(
                                  obj.endDate.split("T")[1].split(":")[0],
                                  true
                                )
                                  ? "Matin"
                                  : "Journée entière"}
                              </span>
                            </div>
                            {obj.commentary && (
                              <div>
                                Motif: <span>{obj.commentary}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        {(moment(obj.startDate.split("T")[0]).isAfter() && (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_MANAGER"))) && <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-danger"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              cancelMyLeaveRequest(obj.id);
                            }}
                          >
                            Annuler
                          </button>
                        </div>}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Paginator
            totalItems={treatedReceivedLeaveRequests[HYDRA_TOTALITEMS]}
            paginate={clickPagination}
          />
        </div>
      ) : (
        <div className="alert alert-info" role="alert">
          Vous n'avez pas encore des demandes traitées
        </div>
      )}
    </>
  );
};

export default TreatedReceivedLeaveRequestsTable;
